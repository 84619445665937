import $ from 'jquery'
import 'es6-promise/auto'

let RegisterForm = (() => {
	let elementAttributes = {
		checkboxAddressSection: 'data-toggle-register-form-address',
		addressSection: 'data-register-form-address',
	}

	let element = {
		registerForm: '[data-register-form]',
		checkBoxCitizen: "[data-register-form-member-type='citizen']",
		checkBoxResidence: "[data-register-form-member-type='residence']",
		selectNationality: '[data-register-form-nationality]',
		selectCountry:
			"[name='physical_address_country'][data-register-form-country]",
		inputHometown: '[data-register-form-hometown]',
		conditionalFieldsets: '[data-register-form-fieldset]',
		submitButton: '[data-register-form-submit]',
		checkboxAddressSection:
			'[' + elementAttributes.checkboxAddressSection + ']',
		addressSection: '[' + elementAttributes.addressSection + ']',
		email: '[data-register-form-email]',
		memberId: '[data-register-form-member-id]',
		inputMainZipCode: '[data-register-form-main-zip-code]',
		inputZipCode: '[data-register-form-zip-code]', // TODO SA: Check, not used anymore?
		mainAutocompleteZip: '[data-register-form-main-autocomplete-zip]',
		autocompleteZip: '[data-register-form-autocomplete-zip]', // TODO SA: Check, not used anymore?
		documentUploadFieldset: '[data-register-form-fieldset-document-upload]',
	}

	let zipNoticeShowed = false

	let init = () => {
		if (!$(element.registerForm).length) {
			return false
		}

		addEventListeners()
		toggleFieldsets()
		toggleAddressSection()
		toggleNationality()
		toggleCountry()
		toggleHometown()
		toggleDocumentUpload()
		toggleDocumentUploadNote()
	}

	const showZipNotice = () => {
		zipNoticeShowed = true

		setTimeout(function() {
			document
				.getElementById('west-switzerland-notice-modal')
				.dispatchEvent(
					new CustomEvent('show-modal', {
						detail: 'west-switzerland-notice-modal',
						bubbles: true,
					})
				)
		}, 250)
	}

	let addEventListeners = () => {
		$(element.registerForm).on('submit', function(e) {
			if (document.getElementById('file-upload-document').files.length > 0) {
				setTimeout(function() {
					document.getElementById('loading-modal').dispatchEvent(
						new CustomEvent('show-modal', {
							detail: 'loading-modal',
							bubbles: true,
						})
					)
				}, 250)
			}

			$(element.submitButton).prop('disabled', true)
		})
		$(element.checkBoxCitizen).on('change', function() {
			toggleFieldsets()
			toggleNationality(event)
			toggleHometown()
			toggleDocumentUpload()
			toggleDocumentUploadNote()
		})
		$(element.checkBoxResidence).on('change', function() {
			toggleFieldsets()
			toggleCountry(event)
			toggleDocumentUpload()
			toggleDocumentUploadNote()
		})
		$(element.checkboxAddressSection).on('change', function() {
			toggleAddressSection(
				$(this).attr(elementAttributes.checkboxAddressSection)
			)
		})
		$(element.selectNationality).on('change', function() {
			toggleHometown()
			toggleCitizen()
		})
		$(element.selectCountry).on('change', function() {
			toggleResidence()
		})
		$(element.memberId).on('keydown paste', numbersOnly)
		$(element.email).on('paste', preventPaste)

		$(element.inputMainZipCode).on('blur', function() {
			const mainCountryIsSwitzerland =
				$(element.mainAutocompleteZip).val() === 'CH'
			const alternativeCountryIsSwitzerland =
				$(element.autocompleteZip).val() === 'CH'
			const mainZipCode = parseInt($(element.inputMainZipCode).val(), 10)
			const alternativeZipCode = parseInt(
				$(element.inputZipCode).val(),
				10
			)

			if (
				!(
					alternativeCountryIsSwitzerland && alternativeZipCode < 2500
				) &&
				(mainCountryIsSwitzerland &&
					mainZipCode.toString().length &&
					mainZipCode < 2500 &&
					!zipNoticeShowed)
			) {
				showZipNotice()
			}
		})

		$(element.mainAutocompleteZip).on('change', function() {
			const mainCountryIsSwitzerland =
				$(element.mainAutocompleteZip).val() === 'CH'
			const alternativeCountryIsSwitzerland =
				$(element.autocompleteZip).val() === 'CH'
			const mainZipCode = parseInt($(element.inputMainZipCode).val(), 10)
			const alternativeZipCode = parseInt(
				$(element.inputZipCode).val(),
				10
			)

			if (
				!(
					alternativeCountryIsSwitzerland && alternativeZipCode < 2500
				) &&
				(mainCountryIsSwitzerland &&
					mainZipCode.toString().length &&
					mainZipCode < 2500 &&
					!zipNoticeShowed)
			) {
				showZipNotice()
			}
		})
	}

	let toggleFieldsets = () => {
		let nationalityChecked = $(element.checkBoxCitizen).is(':checked'),
			residenceChecked = $(element.checkBoxResidence).is(':checked')

		if (nationalityChecked || residenceChecked) {
			$(element.conditionalFieldsets).show()
			$(element.submitButton).prop('disabled', false)
		} else {
			$(element.conditionalFieldsets).hide()
			$(element.submitButton).prop('disabled', true)
		}
	}

	let toggleNationality = () => {
		let nationalityChecked = $(element.checkBoxCitizen).is(':checked')

		if (nationalityChecked) {
			$(element.selectNationality)
				.val('CH')
				.attr('readonly', 'readonly')
		} else {
			$(element.selectNationality).removeAttr('readonly')
		}
	}

	let toggleCountry = () => {
		const residenceChecked = $(element.checkBoxResidence).is(':checked')
		const selectCountryElement = document.querySelector(element.selectCountry)

		if (residenceChecked) {
			selectCountryElement.value = 'CH'
			selectCountryElement.setAttribute('readonly', true)
		} else {
			selectCountryElement.removeAttribute('readonly')
		}

		selectCountryElement.dispatchEvent(new Event('country-change'))
	}

	let toggleHometown = () => {
		if ($(element.selectNationality).val() === 'CH') {
			$(element.inputHometown).show()
		} else {
			$(element.inputHometown).hide()
		}
	}

	let toggleCitizen = () => {
		if ($(element.selectNationality).val() === 'CH') {
			$(element.checkBoxCitizen)
				.prop('checked', true)
				.trigger('change')
		} else {
			$(element.checkBoxCitizen)
				.prop('checked', false)
				.trigger('change')
		}
	}

	let toggleResidence = () => {
		if ($(element.selectCountry).val() === 'CH') {
			$(element.checkBoxResidence)
				.prop('checked', true)
				.trigger('change')
		} else {
			$(element.checkBoxResidence)
				.prop('checked', false)
				.trigger('change')
		}
	}

	let showDocumentUpload = () =>
		($(element.checkBoxCitizen).is(':checked') &&
			!$(element.checkBoxResidence).is(':checked')) ||
		(!$(element.checkBoxCitizen).is(':checked') &&
			$(element.checkBoxResidence).is(':checked'))

	let toggleDocumentUpload = () => {
		$(element.documentUploadFieldset).addClass('u-hidden')

		if (showDocumentUpload()) {
			$(element.documentUploadFieldset).removeClass('u-hidden')
		}
	}

	let toggleDocumentUploadNote = () => {
		if (showDocumentUpload()) {
			let type = $('[data-register-form-member-type]:checked').attr(
				'data-register-form-member-type'
			)
			let $note = $(
				'[data-register-form-document-upload-note=' + type + ']'
			)

			if ($note) {
				$note.siblings().addClass('u-hidden')
				$note.removeClass('u-hidden')
			}
		}
	}

	let toggleAddressSection = (type = false) => {
		if (type) {
			let checkboxAddressSectionChecked = $(
				'[' +
					elementAttributes.checkboxAddressSection +
					'=' +
					type +
					']'
			).is(':checked')

			if (checkboxAddressSectionChecked) {
				$(
					'[' + elementAttributes.addressSection + '=' + type + ']'
				).show()
			} else {
				$(
					'[' + elementAttributes.addressSection + '=' + type + ']'
				).hide()
			}
		} else {
			$(element.checkboxAddressSection).each(function() {
				if ($(this).is(':checked')) {
					let type = $(this).attr(
						elementAttributes.checkboxAddressSection
					)
					$(
						'[' +
							elementAttributes.addressSection +
							'=' +
							type +
							']'
					).show()
				}
			})
		}
	}

	let numbersOnly = e => {
		if (
			// Allow:
			// Backspace, Delete, Tab, Escape, Enter
			// Ctrl + A, Ctrl + C
			// Home, End, Left, Right, Down, Up
			$.inArray(e.keyCode, [46, 8, 9, 27, 13]) !== -1 ||
			((e.keyCode === 65 || e.keyCode === 67) &&
				(e.ctrlKey === true || e.metaKey === true)) ||
			(e.keyCode >= 35 && e.keyCode <= 40)
		) {
			return
		}

		//  Prevent everything, except numbers
		if (
			!(
				(e.keyCode >= 48 && e.keyCode <= 57) ||
				(e.keyCode >= 96 && e.keyCode <= 105)
			)
		) {
			e.preventDefault()
		}
	}

	let preventPaste = e => {
		e.preventDefault()
	}

	return {
		init: init,
	}
})()

$(RegisterForm.init)
