import $ from 'jquery'

let Module = (() => {
	let init = () => {
		$('[data-visible]').each((index, element) => {
			let $this = $(element),
				name = $this.attr('data-visible'),
				value = $this.attr('data-visible-if'),
				$input = $(`[name="${name}"]`)

			if ($input.is(':checkbox')) {
				$input = $(`[name="${name}"]`).last()
				$input
					.on('change', () => {
						let comparison = value == 'false' ? false : true
						if ($input.prop('checked') == comparison) {
							$this.slideDown(250)
							$(document).trigger('toggledContent')
						} else {
							$this.slideUp(250)
						}
					})
					.trigger('change')
			}

			if ($input.is(':radio')) {
				$input
					.on('change', () => {
						if ($(`[name="${name}"]:checked`).val() == value) {
							$this.slideDown(250)
							$(document).trigger('toggledContent')
						} else {
							$this.slideUp(250)
						}
					})
					.trigger('change')
			}

			if ($input.is('select')) {
				$input
					.on('change', () => {
						if ($input.val() == value) {
							$this.slideDown(250)
							$(document).trigger('toggledContent')
						} else {
							$this.slideUp(250)
						}
					})
					.trigger('change')
			}
		})
	}

	return {
		init: init,
	}
})()

$(document).ready(Module.init)
