;(() => {
	window.dropDocuments = function() {
		return {
			droppingDocuments: false,
			handleFileDrop(e) {
				if (e.dataTransfer.files.length > 0) {
					const files = e.dataTransfer.files

					this.$wire.uploadMultiple(
						'documents',
						files,
						uploadedFilename => {},
						() => {},
						event => {}
					)
				}
			},
		}
	}
})()
