import $ from 'jquery'

const DateInput = (() => {
	const element = {
		input: 'input[data-date-input]',
	}

	let lastKey = null,
		lastPos = null

	const init = () => {
		if (!$(element.input)) return false

		$(element.input).on('blur', function() {
			const value = $(this).val()
			const max = $(this).data('date-max') || null

			lastKey = null
			lastPos = null

			if (
				value !== '' &&
				max.toString().length === 2 &&
				value.length < max.toString().length
			) {
				$(this).val(`0${$(this).val()}`)
			}
		})

		$(element.input).on('keydown', function(e) {
			const key = e.which

			if (
				!(
					key === 9 || // Tab
					(event.shiftKey && key === 9) || // Shift + Tab
					key === 37 || // Arrow left
					key === 39 || // Arrow right
					key === 8 || // Backspace
					key === 46 || // Delete
					((key > 47 && key < 58) || (key > 95 && key < 106))
				) // Numbers
			) {
				e.preventDefault()

				return false
			}
		})

		$(element.input).on('keyup', function(e) {
			const value = $(this).val()
			const max = $(this).data('date-max') || null
			const key = e.keyCode || e.charCode
			const pos = $(this)[0].selectionStart

			if (key === 39 && (pos === value.length || value === '')) {
				// Arrow right

				if (
					value === '' ||
					(lastPos === value.length || lastPos === null)
				) {
					$(this)
						.parent()
						.next()
						.find('input')
						.focus()

					return false
				}
			} else if (key === 37 && (pos === 0 || value === '')) {
				// Arrow left

				if (value === '' || (lastPos === 0 || lastPos === null)) {
					$(this)
						.parent()
						.prev()
						.find('input')
						.focus()

					return false
				}
			} else if (key === 8 && (pos === 0 || value === '')) {
				// Backspace

				if (value === '' || lastPos === 0) {
					$(this)
						.parent()
						.prev()
						.find('input')
						.focus()

					return false
				}
			} else if (
				value !== '' &&
				!(
					key === 8 ||
					key === 9 ||
					key === 16 ||
					(event.shiftKey && key === 9) ||
					key === 39 ||
					key === 37
				)
			) {
				let pre = ''

				if (
					value > 0 &&
					value.charAt(0) == 0 &&
					value.length <= max.toString().length
				) {
					pre = '0'
				}

				$(this).val(pre + Math.max(Math.min(value, max), 0))

				if (value.length === max.toString().length) {
					$(this)
						.parent()
						.next()
						.find('input')
						.focus()
				}
			}

			lastKey = key
			lastPos = pos
		})
	}

	return {
		init: init,
	}
})()

$(document).ready(DateInput.init)
