;(() => {
	window.addEventListener(
		'validatorFails',
		e => {
			e.stopPropagation()

			console.log('Scroll to error')

			const firstErrorElement = document.querySelector(
				'.c-notice--warning,[data-field-error]:not(.is-hidden)'
			)

			if (!firstErrorElement) {
				return
			}

			console.log('First error', firstErrorElement)

			const additionalOffset = 20
			const headerElement = document.querySelector(
				'[data-offset-element]'
			)
			const headerOffset = headerElement
				? headerElement.getBoundingClientRect().height
				: 0
			const firstErrorElementRect = firstErrorElement.parentElement.getBoundingClientRect()
			const firstErrorElementPosition = firstErrorElementRect.top
			const firstErrorElementOffsetPosition =
				firstErrorElementPosition +
				window.scrollY -
				headerOffset -
				additionalOffset
			const isInViewport =
				firstErrorElementRect.top >= 0 &&
				firstErrorElementRect.left >= 0 &&
				firstErrorElementRect.bottom <=
					(window.innerHeight ||
						document.documentElement.clientHeight) &&
				firstErrorElementRect.right <=
					(window.innerWidth || document.documentElement.clientWidth)

			if (!isInViewport) {
				window.scrollTo({
					top: firstErrorElementOffsetPosition,
					behavior: 'smooth',
				})
			}
		},
		false
	)
})()
