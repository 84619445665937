export default () => ({
	showSidebarOnMobile: true,
	showMessageOnMobile: false,
	onMessageClick() {
		// Scroll message detail container to top
		this.$refs.mainContainer.scrollTop = 0

		this.showSidebarOnMobile = false
		this.showMessageOnMobile = true
	},
	onBackToSidebarClick() {
		this.showSidebarOnMobile = true
		this.showMessageOnMobile = false
	},
	init() {
		// Scroll sidebar container to selected message
		if (this.$refs.selectedMessage) {
			var topPos = this.$refs.selectedMessage.offsetTop

			this.$refs.sidebar.scrollTop = topPos - 120
		}
	},
})
