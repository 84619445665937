const trim = (str, chars) =>
	str
	.split(chars)
	.filter(Boolean)
	.join(chars)

const controller = new AbortController()
const { signal } = controller

let zipCodeNoticeShown = false

export default () => ({
	usesAlpine: true,
	usesLivewire: false,
	countryRef: 'country',
	streetRef: 'streetAddress',
	zipRef: 'zipCode',
	cityRef: 'city',
	blockCityInput: false,
	suggestions: {
		street: [],
		zip: [],
		city: [],
	},
	isOpen: {
		street: false,
		zip: false,
		city: false,
	},
	useMappings(mappings) {
		this.countryRef = mappings.country
		this.streetRef = mappings.street
		this.zipRef = mappings.zip
		this.cityRef = mappings.city
	},
	useAlpine(mappings) {
		this.usesAlpine = true
		this.usesLivewire = false

		this.useMappings(mappings)
	},
	useLivewire(mappings) {
		this.usesAlpine = false
		this.usesLivewire = true

		this.useMappings(mappings)
	},
	getValue(key) {
		return this.usesAlpine
			? this.$refs[this[key + 'Ref']].value || ''
			: this.$wire.get(this[key + 'Ref']) || ''
	},
	setValue(key, value, force = false) {
		if (value.length || force) {
			if (this.usesLivewire) {
				this.$wire.set(this[key + 'Ref'], value);
			} else if (this.$refs && this.$refs[this[key + 'Ref']]) {
				this.$refs[this[key + 'Ref']].value = value;
				this.$refs[this[key + 'Ref']].dispatchEvent(new Event('input'));
			}

			if ((key === 'zip' || key === 'city') && value === '') {
				this.search(key, value);
			}
		}
	},
	getLength(obj) {
		return Object.keys(obj).length
	},
	onCountryChange() {
		if (this.usesAlpine && this.blockCityInput) {
			// this.setValue('street', '', true)
			// this.setValue('zip', '', true)
			// this.setValue('city', '', true)

			const countryInput = this.$refs[this.countryRef]
			const cityInput = this.$refs[this.cityRef]

			if (countryInput.value === 'CH') {
				cityInput.setAttribute('readonly', true)
				cityInput.setAttribute('placeholder', cityInput.getAttribute('data-placeholder'))
			} else {
				cityInput.removeAttribute('readonly')
				cityInput.removeAttribute('placeholder')
			}
		}
	},
	async search(key, value) {
		if (this.getValue('country') !== 'CH') {
			return false;
		}

		let searchBody = {
			[key]: value
		};

		if (key === 'zip') {
			this.setValue('city', '');
			searchBody.zip = value;
		}
		else if (key === 'city') {
			searchBody.zip = this.getValue('zip');
			searchBody.city = value;
		}
		else {
			let street = value;
			let number = '';
			let numberAddition = '';

			const matchNumber = /^[ \-a-zA-ZäöüÄÖÜß.]+\s+(\d+(\s?\w$)?)/gm.exec(street);
			if (matchNumber) {
				street = street.replace(matchNumber[1], '').trim();
				numberAddition = matchNumber[2];
				number = matchNumber[1].replace(matchNumber[2], '').trim();
			}

			Object.assign(searchBody, {
				street,
				number,
				number_addition: numberAddition,
				zip: this.getValue('zip'),
				city: this.getValue('city')
			});
		}

		const response = await fetch('/api/post-address', {
			method: 'post',
			signal,
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(searchBody),
		});

		let data = await response.json();

		const format = (key === 'zip' || key === 'city')
			? 'ZIP CITY, STREET NUMBERNUMBER_ADDITION'
			: 'STREET NUMBERNUMBER_ADDITION, ZIP CITY';

		this.suggestions[key] = data.map(d => ({
			...d,
			preview: this.formatAddress(d, format)
		}));

		if (this.suggestions[key].length) {
			this.isOpen[key] = true;
		}
	},

	formatAddress(d, format) {
		return format
		.replace('ZIP', d.zip || '')
		.replace('CITY', d.city || '')
		.replace('STREET', d.street || '')
		.replace('NUMBER_ADDITION', d.number_addition || '')
		.replace('NUMBER', d.number || '')
		.replace(/^\,+,\,+$/g, '')
		.replace(/\s+/g, ' ')
		.replace(/ ,/g, ',')
		.trim();
	},
	async show(key) {
		let value = this.getValue(key)

		if (value && value.length) {
			await this.search(key, value)
		}

		if (this.getLength(this.suggestions[key])) {
			this.isOpen[key] = true
		}
	},
	hide(key) {
		this.isOpen[key] = false
	},
	set(key, suggestion) {
		const street = `${suggestion.street} ${suggestion.number}${
			suggestion.number_addition
		}`
		.replace(/^\,+,\,+$/g, '')
		.replace('  ', ' ', 'g')
		.replace(' ,', ',', 'g')
		.trim()

		this.setValue('street', street)
		this.setValue('zip', suggestion.zip)
		this.setValue('city', suggestion.city)

		this.suggestions[key] = []
		this.validateZipCode(suggestion.zip)
	},

	focusedIndex: null,
	startArrowKeys(key, ref) {
		if (this.isOpen[key] && this.getLength(this.suggestions[key])) {
			ref.children[1].focus()
			this.focusedIndex = 1
		}
	},
	focusItem(ref) {
		ref.children[this.focusedIndex].focus()
	},
	focusPrev(key, ref) {
		if (this.focusedIndex > 1) {
			this.focusedIndex = this.focusedIndex - 1
		} else {
			this.focusedIndex = this.getLength(this.suggestions[key])
		}

		this.focusItem(ref)
	},
	focusNext(key, ref) {
		if (this.focusedIndex < this.getLength(this.suggestions[key])) {
			this.focusedIndex = this.focusedIndex + 1
		} else {
			this.focusedIndex = 1
		}

		this.focusItem(ref)
	},

	validateZipCode(zipCode) {
		if (zipCode && +zipCode < 2500 && !zipCodeNoticeShown) {
			this.$dispatch('show-modal', 'west-switzerland-notice-modal')

			zipCodeNoticeShown = true
		}
	},
})
