import Modernizr from 'modernizr'
import jQueryOutside from 'helpers/jquery.outside'

import Alpine from 'alpinejs'

import './modules/validation-handling'
import './modules/register-form'
import './modules/visible'
import './modules/toggle'
import './modules/select-navigation'
import './modules/date-input'
import './modules/drop-documents'
import './modules/drop-attachments'
import './modules/tippy'
import './modules/patient-decree-modal'

import mailbox from './modules/alpine/mailbox'
import addressAutocomplete from './modules/alpine/address-autocomplete'
import registerForm from './modules/alpine/register-form'
import changeMembershipTypeForm from './modules/alpine/change-membership-type-form'

// Alpine.js
window.Alpine = Alpine

Alpine.data('mailbox', mailbox)
Alpine.data('addressAutocomplete', addressAutocomplete)
Alpine.data('registerForm', registerForm)
Alpine.data('changeMembershipTypeForm', changeMembershipTypeForm)

Alpine.start()
