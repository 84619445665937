;(() => {
	window.dropAttachments = function() {
		return {
			droppingAttachments: false,
			handleFileDrop(e, allowMultiple = true) {
				if (e.dataTransfer.files.length > 0) {
					const files = allowMultiple ? e.dataTransfer.files : [e.dataTransfer.files[0]]

					this.$wire.uploadMultiple(
						'attachments',
						files,
						uploadedFilename => {},
						() => {},
						event => {}
					)
				}
			},
		}
	}
})()
