import $ from 'jquery'

// Add jquery event 'outside'
let outsideCollection = []
$.fn.outside = function(ename, cb) {
	return this.each(function() {
		let $this = $(this),
			self = this,
			eventId = cb.toString() // This is the content of the function

		outsideCollection[ename + eventId] = function(e) {
			if (e.target !== self && !$.contains(self, e.target)) {
				cb.call(self, e)
				if (!self.parentNode) {
					$(document.body).off(
						ename,
						outsideCollection[ename + eventId]
					)
				}
			}
		}

		$(document.body).on(ename, outsideCollection[ename + eventId])
	})
}

$.fn.outsideOff = function(ename, cb) {
	// Clear all or a special event handlers from the document
	let func = cb ? outsideCollection[ename + cb.toString()] : outsideCollection
	$(document.body).off(ename, func)
}
