import tippy, { followCursor, roundArrow } from 'tippy.js'

import 'tippy.js/dist/tippy.css'
import 'tippy.js/dist/svg-arrow.css'

// import 'tippy.js/themes/material.css'
// import 'tippy.js/themes/light.css'
// import 'tippy.js/themes/light-border.css'
// import 'tippy.js/themes/translucent.css'
;(() => {
	tippy('[data-tippy]', {
		theme: 'exit',
		arrow: roundArrow,
		allowHTML: true,
		plugins: [followCursor],
		// followCursor: 'horizontal',

		// showOnInit: true,
		// hideOnClick: false,
		// trigger: 'manual',

		// theme: 'material',
		// theme: 'light',
	})
})()
