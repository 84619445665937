import $ from 'jquery'

let Module = (() => {
	let element = {
		select: '[data-select-navigation]',
	}

	let init = () => {
		if (!$(element.select)) {
			return false
		}

		addEventListeners()
	}

	let addEventListeners = () => {
		$(element.select).on('change', switchPage)
	}

	let switchPage = function() {
		window.location = this.options[this.selectedIndex].value
	}

	return {
		init: init,
	}
})()
$(document).ready(Module.init)
