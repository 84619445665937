export default () => ({
	hasMembershipTypeYear: false,
	hasPartnerMemberId: false,
	showMemberTypesDropdown() {
		this.hasMembershipTypeYear = this.$refs.membershipTypeYearly.checked
	},
	showPartnerMemberId() {
		this.hasPartnerMemberId = this.$refs.memberPartner.checked
	},
	uncheck(checkbox) {
		if (checkbox.checked) {
			checkbox.checked = false
		}
	},
	init() {
		this.showMemberTypesDropdown()
		this.showPartnerMemberId()
	},
})
