import $ from 'jquery'

let Module = (() => {
	let element = {
		button: '[data-toggle]',
	}

	let $button, $container

	let init = () => {
		if (!$(element.button)) {
			return false
		}

		addEventListeners()
	}

	let addEventListeners = () => {
		$(element.button).on('click', toggle)
	}

	let toggle = event => {
		$button = $(event.currentTarget)
		$container = $('.' + $button.attr('data-toggle'))

		let isContainerOpen = $container.hasClass('is-open')

		close()

		if (isContainerOpen === true) {
			close.call(this, event)
			event.stopPropagation()
		} else {
			open.call(this, event)
		}
	}

	let open = event => {
		$button = $(event.currentTarget)
		$container = $('.' + $button.attr('data-toggle'))

		event.stopPropagation()

		addOutsideClick()

		$button.addClass('is-open')
		$container.addClass('is-open')
	}

	let close = event => {
		$('.is-open').removeClass('is-open')

		removeOutsideClick()

		if (event) {
			event.preventDefault()
		}
	}

	let addOutsideClick = () => {
		$container.outside('touchstart', close)
		$container.outside('click', close)
	}

	let removeOutsideClick = () => {
		$container.outsideOff('touchstart', close)
		$container.outsideOff('click', close)
	}

	return {
		init: init,
	}
})()
$(document).ready(Module.init)
