import $ from 'jquery'

$(document).ready(() => {
    let modal = document.getElementById('add-patient-decree-modal');

    let submit = modal.querySelector('[name="submit-button"]');
    submit.disabled = true;

    let checkboxes = modal.querySelectorAll('.o-checkbox__input');
    Array.from(checkboxes).forEach(checkbox => {
        checkbox.addEventListener('change', () => {
            if(modal.querySelectorAll('.o-checkbox__input:required:not(:checked)').length === 0) {
                submit.disabled = false;
            } else {
                submit.disabled = true;
            }
        })
    })
})
